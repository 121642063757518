$primary: #E4A1FB;
$secondary: #44A5FF;
$purple: #3d02bd;

$light-purple: #2a047b;
$purple-blue: #3f51b5;

$red-cancel: #E53935;

$gray-disabled: #777;

$button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0,0,0,.12);
$button-box-shadow-hover: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0,0,0,.12);
$text-shadow: 2px 1px 2px black;

@mixin cta-button($background-color: $purple, $color: white) {
  align-items: center;
  border: none;
  border-radius: 4px;
  box-shadow: $button-box-shadow;
  color: $color;
  cursor: pointer;
  background-color: $background-color;
  font-size: 16px;
  height: 40px;

  &:hover, &:active {
    background-color: transparentize($background-color, 0.4);
    box-shadow: $button-box-shadow-hover;
  }

  &:disabled {
    background-color: transparentize($background-color, 0.6);
    box-shadow: none;
  }
}
