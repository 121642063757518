@import '../utils/mixins';

body {
  color: white;
}

.main-container {
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  padding: 52px 0 0;
  position: relative;
  width: 100%;

  &.contain {
    max-width: 1200px;
  }

  &.has-donation {
    padding-bottom: 90px;

    @media (min-width: 1200px) {
      padding-bottom: 80px;
    }
  }

  .navbar {
    background-color: #222;
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2000;

    .navbar-content {
      align-items: center;
      background-color: inherit;
      color: white;
      display: flex;
      height: 52px;
      margin: 0 auto;
      position: relative;
      width: 100%;
      z-index: 8;

      .team-up-button {
        @media (min-width: 1200px) {
          position: absolute;
          right: 200px;
          top: 3px;
          width: 110px;
        }
      }

      .account-tab {
        color: white;

        @media (min-width: 1200px) {
          margin-left: auto;
        }
      }

      .nav-dropdown {
        margin-left: auto;
      }

      .dropdown-button {
        color: white;
      }

      .navbar-tabs {
        width: 100%;

        > div {
          height: 100%;
        }
      }

      .sac-button {
        border: 2px solid transparentize($secondary, 0.3);
        border-radius: 4px;
        color: white;
        margin: 0 16px 0 auto;
        opacity: 0;
        padding: 2px 4px;
        text-transform: none;
        transition: opacity 500ms;

        &.show {
          opacity: 1;
        }

        &:hover {
          border-color: transparentize(white, 0.7);
        }

        @media (min-width: 1200px) {
          margin-right: auto;
        }
      }

      .login-button {
        margin-left: auto;

        a {
          color: white;
          font-size: 14px;
          height: 100%;
          padding-right: 8px;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;
        }
      }

      @media (min-width: 1200px) {
        width: 1200px;
      }
    }

    .success-banner {
      background-color: $secondary;
      border-radius: 0;
      color: $purple;
      height: 48px;
      position: absolute;
      top: 0;
      left: 0;
      transition: top 300ms;
      width: 100%;
      z-index: 1;

      &.open {
        top: 48px;
      }

      @media (min-width: 1200px) {
        border-radius: 4px;
        margin-left: calc(50% - 600px);
        width: 1200px;
      }
    }
  }
}

.footer {
  background-color: black;
  bottom: 0;
  -webkit-box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  left: 0;
  padding-top: 2px;
  position: fixed;
  width: 100vw;

  @media (min-width: 1200px) {
    height: auto;
    padding-top: 0;
  }
}

.sac-modal {
  background-color: white;
  border-radius: 4px;
  color: black;
  margin: 52px auto 0;
  max-width: 600px;
  outline: none;
  padding: 8px 16px;
  text-align: center;
  width: calc(100vw - 16px);

  h3 {
    margin: 24px 0 -8px;
    text-align: center;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }
}

div.nav-dropdown-menu {
  background-color: #222;
  color: white;
  left: 0;

  .menu-item {
    color: inherit;
    text-decoration: none;
    width: 100%;
  }
}

.create-account {
  color: white;

  a {
    color: white;
    text-decoration: none;
  }
}
