@import '../../utils/mixins';

.missions-container {
  margin: 0 auto;
  width: calc(100% - 16px);

  a {
    @include cta-button(white);
    align-items: center;
    color: $purple;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 4px 8px;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 1200px) {
      width: 200px;
    }
  }

  button {
    background-color: white;
    color: $purple;
    display: block;
    margin: 0 auto;
    width: 100%;

    @media (min-width: 1200px) {
      width: 200px;
    }
  }

  @media (min-width: 1200px) {
    width: 1200px;
  }
}
